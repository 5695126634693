/* eslint-disable */
import React from "react";
import { GlobalStyles } from "twin.macro";
import LayoutMui from "../layout/layoutMui";
import { PageTitleProvider } from "./pagetitle-provider";
import { ScreenSizeProvider } from "./screensize-provider";

export const wrapPageElement = ({ element }) => (
  <>
    <GlobalStyles />
    <ScreenSizeProvider>
      <PageTitleProvider>
        <LayoutMui>{element}</LayoutMui>
      </PageTitleProvider>
    </ScreenSizeProvider>
  </>
);
