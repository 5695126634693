import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { node } from "prop-types";
import React from "react";
import theme from "../layout/theme";

const TopLayout = ({ children }) => (
  <>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  </>
);
TopLayout.propTypes = {
  children: node.isRequired,
};

export default TopLayout;
