import { addDays, subDays } from "date-fns";
import dayjs from "dayjs";
import { theme } from "twin.macro";

export const payPerHour = 12;

export const rightNow = new Date();
export const rightNowInt = rightNow.getTime();
export const rightNowString = rightNowInt.toString();

export const timeFilterObjFuture = {
  left: rightNow,
  right: addDays(rightNow, 30),
};

export const timeFilterObjFutureDayjs = {
  left: dayjs(),
  right: dayjs().add(30, "day"),
};

export const timeFilterObjPast = {
  left: subDays(rightNow, 30),
  right: rightNow,
};

export const timeFilterObjPastDayjs = {
  left: dayjs().subtract(30, "day"),
  right: dayjs(),
};

export const twoMonInMSec = 5184000000;

export const weekdays = [
  "Montag",
  "Dienstag",
  "Mittwoch",
  "Donnerstag",
  "Freitag",
  "Samstag",
  "Sonntag",
];
export const weekdaysShort = ["Mo.", "Di.", "Mi.", "Do.", "Fr.", "Sa.", "So."];
export const weekdaysEngl = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

export const drawerWidthSm = 240;
export const drawerWidthLg = 248;

export const mq = {
  sm: `@media (min-width: ${theme`screens.sm`})`,
  md: `@media (min-width: ${theme`screens.md`})`,
  lg: `@media (min-width: ${theme`screens.lg`})`,
  xl: `@media (min-width: ${theme`screens.xl`})`,
};
