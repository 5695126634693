module.exports = {
  content: ["./src/**/*.{js,jsx,ts,tsx}"],
  theme: {
    screens: {
      sm: "600px",
      md: "900px",
      lg: "1200px",
      xl: "1536px",
    },
    extend: {
      colors: {
        prim: {
          50: "#f5e8ef",
          100: "#e2bfd2",
          200: "#d096b5",
          300: "#be6c99",
          400: "#ab437c",
          500: "#991A5F",
          600: "#7d154e",
          700: "#62113d",
          800: "#460c2c",
          900: "#2b071b",
        },
        sec: {
          50: "#ecfdf5",
          100: "#d1fae5",
          200: "#a7f3d0",
          300: "#6ee7b7",
          400: "#34d399",
          500: "#10b981",
          600: "#059669",
          700: "#047857",
          800: "#065f46",
          900: "#064e3b",
        },
        tert: {
          50: "#f1eeef",
          100: "#d8d0d3",
          200: "#bfb2b6",
          300: "#a5939a",
          400: "#8c757d",
          500: "#735761",
          600: "#5e4750",
          700: "#4a383e",
          800: "#35282d",
          900: "#20181b",
        },
      },
      spacing: {
        18: "4.5rem",
        22: "5.5rem",
        26: "6.5rem",
        30: "7.5rem",
        88: "22rem",
        104: "26rem",
        112: "28rem",
        120: "30rem",
        128: "32rem",
        160: "40rem",
        192: "48rem",
        224: "56rem",
        256: "64rem",
        320: "80rem",
        384: "96rem",
        448: "112rem",
        512: "128rem",
      },
      cursor: {
        "ns-resize": "ns-resize",
      },
      maxWidth: {
        "screen-sm": "600px",
        "screen-md": "900px",
        "screen-lg": "1200px",
        "2xs": "14rem",
        "3xs": "10rem",
        "4xs": "8rem",
        "5xs": "6rem",
      },
      minWidth: {
        10: "2.5rem",
      },
      boxShadow: {
        "upper-sm": "0 -1px 2px 0 rgba(0, 0, 0, 0.05)",
        upper:
          "0 -1px 3px 0 rgba(0, 0, 0, 0.1), 0 -1px 2px 0 rgba(0, 0, 0, 0.06)",
        "upper-md":
          "0 -4px 6px -1px rgba(0, 0, 0, 0.1), 0 -2px 4px -1px rgba(0, 0, 0, 0.06)",
        "upper-lg":
          "0 -10px 15px -3px rgba(0, 0, 0, 0.1), 0 -4px 6px -2px rgba(0, 0, 0, 0.05)",
      },
    },
  },
  variants: {
    extend: {
      backgroundColor: ["responsive", "hover", "focus", "active"],
      borderColor: ["responsive", "hover", "focus", "active", "visited"],
      borderRadius: ["responsive", "hover", "focus", "active"],
      borderWidth: ["responsive", "hover", "focus", "active"],
      transform: ["responsive", "hover", "focus", "active"],
    },
  },
  plugins: [],
};
