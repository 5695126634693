const { gql } = require("@apollo/client");

export const USER_DATA_QUERY = gql`
  query GetUser {
    getUser {
      id
      first_name
      last_name
      email
      date_of_birth
      phone
      address {
        zip
        city
        street
        number
      }
    }
  }
`;

// export const USER_JOBINSTANCES_QUERY = gql`
//   query ListUserJobInstances($start_time: Float) {
//     listUserJobInstances(start_time: $start_time) {
//       id
//       description
//       job {
//         title
//         requirements {
//           title
//         }
//       }
//       department {
//         name
//         info {
//           contact {
//             name
//             tel
//           }
//         }
//         facility {
//           name
//           address {
//             street
//             number
//             zip
//             city
//           }
//           info {
//             contact {
//               name
//               email
//               tel
//             }
//             general_info
//             emergency_info
//             emergency_tel
//             scrubs
//           }
//         }
//         specialty
//       }
//       start_time
//       length
//       pay
//     }
//   }
// `

export const LIST_JOBS_QUERY = gql`
  query ListJobs {
    listJobs {
      id
      title
      sugg_descr
      requirements {
        title
      }
    }
  }
`;

export const LIST_DEPARTMENTS_QUERY = gql`
  query ListDepartments($facility: String!) {
    listDepartments(facility: $facility) {
      id
      name
      specialty
    }
  }
`;

export const GET_USER_DEPTS = gql`
  query GetUserDepts {
    getUser {
      departments {
        id
        name
        facility {
          id
          name
        }
      }
    }
  }
`;

export const GET_USER_ROLES = gql`
  query GetUserRoles {
    getUser {
      id
      roles
      calendar
      facilities {
        id
        name
        active_users
        address {
          city
          zip
          number
          street
        }
        departments {
          id
          name
          facility {
            id
          }
        }
        users {
          role
          user {
            id
            first_name
            last_name
            email
          }
        }
        pools {
          id
          title
        }
        billing {
          stripe_customer_id
          current_subscription {
            id
            stripe_id
            start
            end
            status
            plan {
              id
              title
              description
              stripe_id
              status
              monthly_active_users
              limits {
                metric
                limit
              }
              price
            }
          }
        }
      }
    }
  }
`;
