/* eslint-disable react/function-component-definition */
import React from "react";
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { setContext } from "@apollo/client/link/context";
import fetch from "isomorphic-fetch";
import { node } from "prop-types";
// import LoadingBouncer from "../components/LoadingBouncer"

const AuthApolloProvider = ({ children }) => {
  const {
    // isLoading,
    error,
    isAuthenticated,
    getAccessTokenSilently,
  } = useAuth0();

  // API endpoint of JiM API
  const httpLink = createHttpLink({
    uri: process.env.GATSBY_JIM_API,
  });

  const authLink = setContext(async (_, { headers }) => {
    const token = await getAccessTokenSilently();
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  const defaultOptions = {
    watchQuery: {
      fetchPolicy: "network-only",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "network-only",
      // errorPolicy: 'all',
    },
    // mutate: {
    //   errorPolicy: 'all'
    // }
  };

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    defaultOptions,
    fetch,
  });

  // if (isLoading) return <LoadingBouncer size="md" fullscreen />
  if (error) return <div>Auth0 Error: {error.message}</div>;

  return (
    (isAuthenticated === true || isAuthenticated === false) && (
      <ApolloProvider client={client}>{children}</ApolloProvider>
    )
  );
};
AuthApolloProvider.propTypes = {
  children: node.isRequired,
};

export default AuthApolloProvider;
