/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, react/function-component-definition */
import React, { useContext, useState } from "react";
import { AppBar, Drawer, IconButton, Toolbar, useTheme } from "@mui/material";
import {
  AccountCircleRounded,
  MenuTwoTone,
  NavigateNextRounded,
} from "@mui/icons-material";
import tw, { styled } from "twin.macro";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "gatsby";
// import { Transition, TransitionGroup } from "react-transition-group"
import { useLocation } from "@reach/router"; // eslint-disable-line import/no-unresolved
import { node, string } from "prop-types";

import Header from "./Header";
import NavDrawer from "./NavDrawer";
import { ScreenSizeContext } from "../utils/screensize-provider";
import { JimDialog, MyContainer } from "../components/util-components";
import { PageTitleContext } from "../utils/pagetitle-provider";
import { drawerWidthLg, drawerWidthSm, mq } from "../utils/global-consts";
import FaciliyMenu from "./facilityMenu";
// import JimAvatar from "../images/JimAvatar"

const JimAppBar = styled(AppBar)(() => [
  tw`fixed shadow-md`,
  `${mq.sm} {
    width: calc(100% - ${drawerWidthSm}px);
    margin-left: ${drawerWidthSm}px;
  }
  ${mq.lg} {
    width: calc(100% - ${drawerWidthLg}px);
    margin-left: ${drawerWidthLg}px;
  }`,
]);

const JimNav = styled.nav(() => [
  tw`w-0 sm:w-52 lg:w-60 flex-shrink-0 print:hidden`,
  `${mq.sm} {
    width: ${drawerWidthSm}px;
  }
  ${mq.lg} {
    width: ${drawerWidthLg}px;
  }`,
]);

const JimDrawer = styled(Drawer)(({ theme }) => [
  tw`flex-shrink-0`,
  `width: ${drawerWidthLg}px;
  ${mq.sm} {
    width: ${drawerWidthSm}px;
  }
  ${mq.lg} {
    width: ${drawerWidthLg}px;
  }
  & .MuiDrawer-paper {
    background-color: ${theme.palette.primary.main};
    width: ${drawerWidthLg}px;
    color: white;
    overflow-x: hidden;
    ${mq.sm} {
      width: ${drawerWidthSm}px;
    }
    ${mq.lg} {
      width: ${drawerWidthLg}px;
    }
  }`,
]);

const SpaceTopMobile = styled.div`
  min-height: 56px;
`;

const ActualContentWrapper = styled.div(({ theme }) => [
  tw`flex-grow`,
  `
${theme.breakpoints.up("sm")} {
    max-height: calc(100vh - 5rem);
  }
  ${theme.breakpoints.up("xl")} {
    max-height: calc(100vh - 6rem);
  }
`,
]);

// const TransitionWrapper = styled.div(({ state }) => [
//   tw`transition duration-1000`,
//   state === "entering" && tw`absolute opacity-0`,
//   state === "entered" && tw`opacity-100`,
//   state === "exiting" && tw`opacity-0`,
//   state === "exited" && tw`opacity-0`,
// ])

const SecNavLink = ({ to, logo, title }) => {
  const { pathname } = useLocation();
  return (
    <div tw="py-2">
      <Link
        tw="flex items-center justify-between p-2 cursor-pointer opacity-70 transition duration-100 rounded-full hover:(opacity-100 bg-gray-200)"
        partiallyActive
        activeStyle={tw`opacity-100 font-semibold bg-gray-300`}
        to={`/employer/${
          pathname.split("employer/")[1].split("/")[0]
        }/facility-settings/${to}`}
      >
        <div tw="flex items-center max-w-full truncate">
          {logo}
          <span tw="hidden md:inline">{title}</span>
        </div>
        <NavigateNextRounded tw="hidden md:inline" />
      </Link>
    </div>
  );
};

SecNavLink.propTypes = {
  to: string.isRequired,
  logo: node.isRequired,
  title: string.isRequired,
};

// eslint-disable-next-line react/prop-types
const LayoutMui = ({ window, children }) => {
  const screen = useContext(ScreenSizeContext);
  const { pathname } = useLocation();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const { pageTitle } = useContext(PageTitleContext);
  const { logout, user } = useAuth0();
  const [showUserDialog, setShowUserDialog] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const handleLogout = e => {
    e.stopPropagation();
    logout();
  };

  return (
    <div tw="flex">
      {screen.xsDown && (
        <JimAppBar>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              size="large"
            >
              <MenuTwoTone />
            </IconButton>
            <div tw="flex flex-grow h-8 justify-center text-lg font-semibold tracking-wider overflow-hidden">
              <div tw="truncate">{pageTitle}</div>
            </div>
            <div onClick={() => setShowUserDialog(true)}>
              <AccountCircleRounded tw="text-4xl text-white" />
            </div>
          </Toolbar>
        </JimAppBar>
      )}
      <JimNav>
        {screen.xsDown && (
          <JimDrawer
            theme={theme}
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <NavDrawer setMobileOpen={setMobileOpen} />
          </JimDrawer>
        )}
        {screen.smUp && (
          <JimDrawer theme={theme} variant="permanent" open>
            <NavDrawer setMobileOpen={setMobileOpen} />
          </JimDrawer>
        )}
      </JimNav>
      <div tw="flex flex-col md:flex-row flex-grow">
        {pathname.includes("facility-settings") && <FaciliyMenu />}
        <main tw="flex flex-col flex-grow h-screen max-w-full">
          {screen.xsDown && (
            <>
              {!pathname.includes("facility-settings") && <SpaceTopMobile />}
              <JimDialog
                open={showUserDialog}
                handleClose={() => setShowUserDialog(false)}
                tw="p-0 space-y-4"
              >
                <div tw="p-4 bg-gray-200 rounded-t-md text-center">
                  {user.email}
                </div>
                <button type="button" onClick={() => setShowUserDialog(false)}>
                  <Link tw="py-2 px-4 text-lg" to="/personal-data">
                    Persönliche Daten
                  </Link>
                </button>
                <button
                  type="button"
                  onClick={handleLogout}
                  tw="py-2 px-4 text-lg"
                >
                  Logout
                </button>
              </JimDialog>
            </>
          )}
          {screen.smUp && (
            <div tw="print:hidden">
              <MyContainer>
                <Header title={pageTitle} />
              </MyContainer>
            </div>
          )}
          <ActualContentWrapper theme={theme}>
            <MyContainer>{children}</MyContainer>
          </ActualContentWrapper>
        </main>
      </div>
    </div>
  );
};
LayoutMui.propTypes = {
  children: node.isRequired,
};

export default LayoutMui;
