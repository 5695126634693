import { useQuery } from "@apollo/client";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import dayjs from "dayjs";
import "dayjs/locale/de";
import { flatten } from "lodash";
import { node } from "prop-types";
import React, { useEffect, useState } from "react";
import tw from "twin.macro";

import LoadingBouncer from "../components/LoadingBouncer";
import { SupportTile } from "../components/util-components";
import { GET_USER_ROLES } from "./queries";

export const UserRolesContext = React.createContext();

const ErrorPageWrapper = tw.div`flex items-center justify-center w-screen h-screen`;

dayjs.locale("de");

function UserRolesProvider({ children }) {
  const [roles, setRoles] = useState([]);
  const [userID, setUserID] = useState(null);
  const [userCal, setUserCal] = useState(null);
  const [userFacilitiesByID, setUserFacilitiesByID] = useState({});
  const [userFacilitiesList, setUserFacilitiesList] = useState([]);
  const [userDeptsByID, setUserDeptsByID] = useState({});
  const [userDeptsList, setUserDeptsList] = useState([]);
  const { data, loading, error } = useQuery(GET_USER_ROLES);

  useEffect(() => {
    if (data && data.getUser) {
      setRoles(data.getUser.roles);
      setUserID(data.getUser.id);
      setUserCal(data.getUser.calendar);
      if (data.getUser.facilities && data.getUser.facilities[0]) {
        setUserDeptsList(
          flatten(data.getUser.facilities.map(facility => facility.departments))
        );
        setUserFacilitiesList(data.getUser.facilities);
        const facilitiesByID = {};
        data.getUser.facilities.forEach(facility => {
          facilitiesByID[facility.id] = facility;
        });
        setUserFacilitiesByID(facilitiesByID);
        const deptsByID = {};
        data.getUser.facilities.forEach(facility => {
          facility.departments.forEach(dept => {
            deptsByID[dept.id] = dept;
          });
        });
        setUserDeptsByID(deptsByID);
      }
    }
  }, [data]);

  if (loading) return <LoadingBouncer size="md" fullscreen />;

  if (error)
    return (
      <ErrorPageWrapper>
        <SupportTile text="Leider ist etwas schief gelaufen. Bitte kontaktiere unseren Support." />
      </ErrorPageWrapper>
    );

  return (
    <UserRolesContext.Provider
      value={{
        roles,
        userID,
        userCal,
        userFacilitiesByID,
        userFacilitiesList,
        userDeptsByID,
        userDeptsList,
      }}
    >
      {children}
    </UserRolesContext.Provider>
  );
}
UserRolesProvider.propTypes = {
  children: node.isRequired,
};

export default withAuthenticationRequired(UserRolesProvider);
