/* eslint-disable import/prefer-default-export */
import React from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { navigate } from "gatsby";
import PropTypes from "prop-types";

import AuthApolloProvider from "./auth-apollo-provider";
import TopLayout from "./top-layout";
import UserRolesProvider from "./userroles-provider";

const onRedirectCallback = appState => {
  navigate(appState?.returnTo || "/", { replace: true });
};

const redirectUri = typeof window !== "undefined" ? window.location.origin : "";

export const wrapRootElement = ({ element }) => (
  <Auth0Provider
    domain={process.env.GATSBY_AUTH0_DOMAIN}
    clientId={process.env.GATSBY_AUTH0_CLIENT_ID}
    audience={process.env.GATSBY_AUTH0_AUDIENCE}
    redirectUri={redirectUri}
    scope="openid profile email"
    onRedirectCallback={onRedirectCallback}
  >
    <AuthApolloProvider>
      <TopLayout>
        <UserRolesProvider>{element}</UserRolesProvider>
      </TopLayout>
    </AuthApolloProvider>
  </Auth0Provider>
);
wrapRootElement.propTypes = {
  element: PropTypes.element.isRequired,
};
