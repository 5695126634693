/* eslint-disable react/function-component-definition */
import React from "react";
import { useLocation } from "@reach/router"; // eslint-disable-line import/no-unresolved
import { Link } from "gatsby";
import {
  DescriptionRounded,
  GroupRounded,
  NavigateNextRounded,
  PersonRounded,
  SettingsRounded,
} from "@mui/icons-material";
import { node, string } from "prop-types";
import tw from "twin.macro";

const SecNavLink = ({ to, logo, title }) => {
  const { pathname } = useLocation();
  return (
    <div tw="py-2">
      <Link
        tw="flex items-center justify-between p-2 cursor-pointer opacity-70 transition duration-100 rounded-full hover:(opacity-100 bg-gray-200)"
        partiallyActive
        activeStyle={tw`opacity-100 font-semibold bg-gray-300`}
        to={`/employer/${
          pathname.split("employer/")[1].split("/")[0]
        }/facility-settings/${to}`}
      >
        <div tw="flex items-center max-w-full truncate">
          {logo}
          <span tw="hidden lg:inline">{title}</span>
        </div>
        <NavigateNextRounded tw="hidden lg:inline" />
      </Link>
    </div>
  );
};

SecNavLink.propTypes = {
  to: string.isRequired,
  logo: node.isRequired,
  title: string.isRequired,
};

const FaciliyMenu = () => (
  <div tw="print:hidden">
    <nav tw="flex flex-col md:h-full px-2 xl:px-8">
      <div tw="h-14 sm:h-0 md:h-24 xl:h-32" />
      <div tw="flex justify-around md:(flex-col justify-start) lg:w-44 xl:w-48">
        <SecNavLink
          to="edit"
          logo={<SettingsRounded tw="lg:mr-2 text-xl" />}
          title="Allgemein"
        />
        <SecNavLink
          to="job-templates"
          logo={<DescriptionRounded tw="lg:mr-2 text-xl" />}
          title="Jobvorlagen"
        />
        <SecNavLink
          to="pools"
          logo={<GroupRounded tw="lg:mr-2 text-xl" />}
          title="Pools"
        />
        <SecNavLink
          to="manage-jims"
          logo={<PersonRounded tw="lg:mr-2 text-xl" />}
          title="JiMs"
        />
        {/* <div tw="py-2">
          <Link
            to="/create-facility"
            tw="flex items-center md:(w-full mt-12) text-white bg-blue-500 rounded-lg font-semibold p-2 opacity-80 hover:opacity-100 transition duration-200"
          >
            <AddRounded tw="lg:mr-2 text-xl" />
            <span tw="hidden lg:inline">Neue Einrichtung</span>
          </Link>
        </div> */}
      </div>
    </nav>
  </div>
);

export default FaciliyMenu;
