/* eslint-disable react/function-component-definition */
import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Modal } from "@mui/material";
import tw, { styled } from "twin.macro";
import { Link } from "gatsby";
import { string } from "prop-types";
// import { useLocation } from "@reach/router"; // eslint-disable-line import/no-unresolved
import { AccountCircleRounded } from "@mui/icons-material";
import { useLocation } from "@reach/router"; // eslint-disable-line import/no-unresolved

import { MyContainer } from "../components/util-components";
import { drawerWidthLg, drawerWidthSm, mq } from "../utils/global-consts";
// import { UserRolesContext } from "../utils/userroles-provider";

const Wrapper = tw.div`flex h-20 xl:h-24 px-2 justify-between items-center text-lg tracking-wider font-semibold top-0`;
const MyModal = styled(Modal)(() => [
  tw`flex items-start w-full h-full`,
  `${mq.sm} {
    padding-left: ${drawerWidthSm}px;
  }
  ${mq.lg} {
    padding-left: ${drawerWidthLg}px;
  }`,
]);

const AvatarMenuLink = tw.div`p-2 text-sm font-normal tracking-normal cursor-pointer bg-white pointer-events-auto hover:bg-gray-100 transition duration-200`;

const Header = ({ title }) => {
  const { pathname } = useLocation();
  // const { userFacilitiesByID } = useContext(UserRolesContext);
  const { user, logout } = useAuth0();
  const [dialogOpen, setDialogOpen] = useState(false);
  // const { pathname } = useLocation();

  const handleClickOnBadge = e => {
    e.preventDefault();
    e.stopPropagation();
    setDialogOpen(!dialogOpen);
  };

  const handleClickAway = () => {
    setDialogOpen(false);
  };

  const handleLogout = e => {
    e.stopPropagation();
    logout();
  };

  return (
    <Wrapper>
      <div tw="flex">
        {/* {pathname.split("/").includes("facility") &&
          pathname.split("facility/")[1].split("/").length > 1 && (
            <div tw="flex items-end text-gray-500">
              <Link
                tw="hover:text-black transition duration-200"
                to={`/employer/facility/${
                  pathname.split("facility/")[1].split("/")[0]
                }`}
              >
                {
                  userFacilitiesByID[
                    pathname.split("facility/")[1].split("/")[0]
                  ].name
                }
              </Link>
              <NavigateNextRounded />
            </div>
          )}
        {pathname.split("/").includes("dept") &&
          pathname.split("dept/")[1].split("/").length > 2 && (
            <div tw="flex items-end text-gray-500">
              <Link
                tw="hover:text-black transition duration-200"
                to={`/employer/dept/${
                  pathname.split("dept/")[1].split("/")[0]
                }/${pathname.split("dept/")[1].split("/")[1]}`}
              >
                {userDeptsByID[pathname.split("dept/")[1].split("/")[1]].name}
              </Link>
              <NavigateNextRounded />
            </div>
          )}
        {pathname.split("/").includes("jims") && (
          <div tw="flex items-end text-gray-500">
            <Link
              tw="hover:text-black transition duration-200"
              to={`/employer/facility/${
                pathname.split("facility/")[1].split("/")[0]
              }/pools`}
            >
              Pools verwalten
            </Link>
            <NavigateNextRounded />
          </div>
        )} */}
        {/* {pathname.split("/").includes("facility") &&
          pathname.split("facility/")[1].split("/").length > 1 && (
            <div tw="flex items-end text-gray-500">
              {
                userFacilitiesByID[pathname.split("facility/")[1].split("/")[0]]
                  .name
              }
              <NavigateNextRounded />
            </div>
          )} */}
        {title}
      </div>
      <AccountCircleRounded
        tw="bg-white rounded-full text-3xl text-prim-500 fill-current cursor-pointer"
        onClick={handleClickOnBadge}
      />
      <MyModal open={dialogOpen} onClose={handleClickAway}>
        <div tw="flex flex-col md:flex-row flex-grow">
          {pathname.includes("facility-settings") && (
            <div tw="px-2 xl:px-8">
              <div tw="hidden sm:flex lg:w-44 xl:w-48">
                <div tw="flex py-4 px-2">
                  <AccountCircleRounded tw="text-xl" />
                </div>
              </div>
            </div>
          )}
          <MyContainer tw="flex flex-col focus:outline-none items-end pointer-events-none px-2">
            <div tw="flex items-center h-20 xl:h-24">
              <AccountCircleRounded
                tw="bg-white rounded-full text-3xl text-prim-500 fill-current cursor-pointer"
                onClick={handleClickOnBadge}
              />
            </div>
            <div>
              <div tw="py-1 px-2 text-sm font-normal bg-gray-200 tracking-wide rounded-t-md pointer-events-auto">
                {user.email}
              </div>
              <Link to="/personal-data">
                <AvatarMenuLink onClick={handleClickAway}>
                  Persönliche Daten
                </AvatarMenuLink>
              </Link>
              <Link to="/jobs/past">
                <AvatarMenuLink onClick={handleClickAway}>
                  Vergangene Einsätze
                </AvatarMenuLink>
              </Link>
              <Link to="/create-facility" tw="text-blue-500">
                <AvatarMenuLink onClick={handleClickAway}>
                  Einrichtung erstellen
                </AvatarMenuLink>
              </Link>
              <AvatarMenuLink onClick={handleLogout} tw="rounded-b-md">
                Logout
              </AvatarMenuLink>
            </div>
          </MyContainer>
        </div>
      </MyModal>
    </Wrapper>
  );
};
Header.propTypes = {
  title: string,
};
Header.defaultProps = {
  title: "",
};

export default Header;
