import { createTheme } from "@mui/material/styles";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "../../tailwind.config";

const twConfig = resolveConfig(tailwindConfig);

const theme = createTheme({
  spacing: 4,
  palette: {
    primary: {
      main: twConfig.theme.colors.prim[500],
      light: twConfig.theme.colors.prim[200],
      dark: twConfig.theme.colors.prim[700],
      contrastText: "#fff",
    },
    secondary: {
      main: twConfig.theme.colors.sec[500],
    },
    tertiary: {
      main: twConfig.theme.colors.tert[500],
    },
    background: {
      default: twConfig.theme.colors.gray[100],
    },
    error: {
      main: twConfig.theme.colors.red[500],
    },
    warning: {
      main: twConfig.theme.colors.amber[500],
    },
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  components: {
    MuiTextField: {
      defaultProps: {
        margin: "dense",
        size: "small",
      },
    },
    MuiSelect: {
      defaultProps: {
        size: "small",
      },
    },
  },
});

export default theme;
