/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, react/function-component-definition */
import React, { useContext, useEffect, useState } from "react";
import { bool, func, node, string } from "prop-types";
import { MenuItem, TextField } from "@mui/material";
import {
  EventAvailableTwoTone,
  VerifiedUserRounded,
  EmailRounded,
  NextWeekTwoTone,
  AddBoxTwoTone,
  SettingsRounded,
  SearchTwoTone,
} from "@mui/icons-material";
import { Link, navigate } from "gatsby";
import tw, { css, styled } from "twin.macro"; // eslint-disable-line no-unused-vars
import { useLocation } from "@reach/router"; // eslint-disable-line import/no-unresolved
import { Transition, TransitionGroup } from "react-transition-group";

import JimLogoNew from "../images/JimLogoNew";
import { UserRolesContext } from "../utils/userroles-provider";
import { JimDialog, TextButton } from "../components/util-components";
import { PageTitleContext } from "../utils/pagetitle-provider";

const TransitionWrapper = styled.div(({ state }) => [
  tw`transition duration-200 transform`,
  state === "entering" && tw`absolute opacity-0 -translate-x-4`,
  state === "entered" && tw`opacity-100`,
  state === "exiting" && tw`opacity-0 translate-x-4`,
  state === "exited" && tw`opacity-0 translate-x-4`,
]);

const NavLink = ({
  onClick,
  to,
  logo,
  title,
  isEmployer,
  isDept,
  isFacility,
  isSelected,
}) => (
  <Link
    css={[
      tw`flex w-full items-center pl-4 sm:pl-2 lg:pl-4 py-2 text-white text-lg tracking-wide transition-all duration-200`,
      isEmployer ? tw`hover:bg-prim-300 rounded` : tw`hover:bg-prim-600`,
      isDept && tw`tracking-wider font-semibold`,
      isFacility &&
        (isSelected ? tw`text-base py-2` : tw`text-sm py-1 opacity-50`),
    ]}
    partiallyActive
    activeStyle={isEmployer ? tw`bg-prim-300 text-lg` : tw`bg-prim-700`}
    onClick={onClick}
    to={to}
  >
    {logo}
    <div tw="max-w-full truncate">{title}</div>
  </Link>
);
NavLink.propTypes = {
  onClick: func.isRequired,
  to: string.isRequired,
  logo: node,
  title: string.isRequired,
  isEmployer: bool,
  isDept: bool,
  isFacility: bool,
  isSelected: bool,
};
NavLink.defaultProps = {
  logo: undefined,
  isEmployer: false,
  isDept: false,
  isFacility: false,
  isSelected: false,
};

const AdminNavLink = ({ onClick, to, title }) => (
  <Link
    tw="flex w-full items-center pl-4 sm:pl-2 lg:pl-4 py-2 text-white text-lg tracking-widest transition duration-200 hover:bg-prim-600 font-mono"
    activeStyle={tw`bg-prim-700`}
    partiallyActive
    onClick={onClick}
    to={to}
  >
    <VerifiedUserRounded tw="mr-2" />
    <div tw="max-w-full truncate">{title}</div>
  </Link>
);
AdminNavLink.propTypes = {
  onClick: func.isRequired,
  to: string.isRequired,
  title: string.isRequired,
};

// const FacilityArea = ({ facID, handleClick, facilitySel, setFacilitySel }) => {
//   const { userFacilitiesByID } = useContext(UserRolesContext)
//   const [isSel, toggleSel] = useState(facilitySel === facID)
//   useEffect(() => toggleSel(facilitySel === facID), [facilitySel])

//   const handleClickOnFacility = e => {
//     handleClick(e)
//     setFacilitySel(facID)
//   }

//   return (
//     <>
//       <NavLink
//         onClick={handleClickOnFacility}
//         to={`/employer/facility/${facID}`}
//         title={userFacilitiesByID[facID].name}
//         isEmployer
//         isFacility
//         isSelected={isSel}
//       />
//       <Collapse in={isSel} tw="pl-4 border-b-2 border-prim-500">
//         {userFacilitiesByID[facID].departments.map(dept => (
//           <NavLink
//             key={dept.id}
//             onClick={e => handleClick(e)}
//             to={`/employer/dept/${facID}/${dept.id}`}
//             logo={<HomeWorkTwoTone tw="mr-2" />}
//             title={dept.name}
//             isEmployer
//             isDept
//           />
//         ))}
//         <NavLink
//           onClick={e => handleClick(e)}
//           to={`/employer/availabilities/${facID}`}
//           logo={<EventAvailableTwoTone tw="mr-2" />}
//           title="Verfügbarkeiten"
//           isEmployer
//         />
//         <NavLink
//           onClick={e => handleClick(e)}
//           to={`/employer/create-job/${facID}`}
//           logo={<WorkTwoTone tw="mr-2" />}
//           title="Job erstellen"
//           isEmployer
//         />
//       </Collapse>
//     </>
//   )
// }
// FacilityArea.propTypes = {
//   facID: string.isRequired,
//   handleClick: func.isRequired,
//   facilitySel: string,
//   setFacilitySel: func.isRequired,
// }
// FacilityArea.defaultProps = {
//   facilitySel: null,
// }

const FooterLink = tw.a`flex items-center px-3 py-0.5 opacity-90 hover:underline`;
const JimToolbar = styled.div`
  min-height: 52px;
`;

const FooterLinkToMM = ({ to, title }) => (
  <FooterLink
    href={`https://www.morrowmed.de${to}`}
    target="_blank"
    rel="noopener"
  >
    {title}
  </FooterLink>
);
FooterLinkToMM.propTypes = {
  to: string.isRequired,
  title: string.isRequired,
};

const NavDrawer = ({ setMobileOpen }) => {
  const { setPageTitle } = useContext(PageTitleContext);
  const { roles, userFacilitiesByID } = useContext(UserRolesContext);
  const { pathname } = useLocation();
  const [facilitySel, setFacilitySel] = useState(null);
  const [showSupportDialog, setShowSupportDialog] = useState(false);

  useEffect(() => {
    if (pathname.split("/").includes("employer")) {
      setFacilitySel(pathname.split("/")[2]);
    } else if (Object.keys(userFacilitiesByID)[0]) {
      setFacilitySel(Object.keys(userFacilitiesByID)[0]);
    }
  }, []);

  const handleClick = e => {
    e.stopPropagation();
    setMobileOpen(false);
  };

  const handleFacilityChange = e => {
    setFacilitySel(e.target.value);
    if (pathname.split("/").includes("edit")) {
      setPageTitle(`${userFacilitiesByID[e.target.value].name} verwalten`);
    }
    if (pathname.split("/").includes("employer")) {
      const oldFacility = pathname.split("mployer/")[1].split("/")[0];
      navigate(pathname.replace(oldFacility, e.target.value));
    }
  };

  return (
    <>
      <JimToolbar>
        <div tw="w-1/3 p-4">
          <Link to="/" onClick={handleClick}>
            <JimLogoNew col1="#F0DEE8" col2="#E1BDD1" col3="#D39CBA" />
          </Link>
        </div>
      </JimToolbar>
      {roles.includes("EMPLOYER") && (
        <>
          {facilitySel && (
            <div tw="flex items-center m-2">
              {Object.keys(userFacilitiesByID).length > 1 ? (
                <TextField
                  id="sel-facility"
                  value={facilitySel}
                  onChange={handleFacilityChange}
                  tw="p-2 bg-prim-400 rounded tracking-wide"
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  SelectProps={{
                    sx: {
                      color: "white",
                      fontSize: "1rem",
                      lineHeight: "1.5rem",
                      "& .MuiSvgIcon-root": {
                        color: "white",
                      },
                    },
                  }}
                  margin="none"
                  select
                  fullWidth
                >
                  {Object.keys(userFacilitiesByID).map(facID => (
                    <MenuItem key={facID} value={facID}>
                      {userFacilitiesByID[facID].name}
                    </MenuItem>
                  ))}
                </TextField>
              ) : (
                <TextField
                  id="sel-facility-single"
                  value={userFacilitiesByID[facilitySel].name}
                  tw="p-2 bg-prim-400 rounded tracking-wide"
                  InputProps={{
                    disableUnderline: true,
                    style: tw`text-white text-base`,
                  }}
                  margin="none"
                  fullWidth
                  disabled
                />
              )}
              <Link to={`/employer/${facilitySel}/facility-settings/edit`}>
                <SettingsRounded tw="ml-2 text-3xl opacity-70 hover:(opacity-100 bg-prim-700) p-1 rounded-full cursor-pointer transition duration-200" />
              </Link>
            </div>
          )}
          <div tw="mx-2 mb-2 bg-prim-400 rounded">
            <TransitionGroup>
              <Transition key={facilitySel} timeout={200}>
                {state => (
                  <TransitionWrapper state={state}>
                    <NavLink
                      onClick={e => handleClick(e)}
                      to={`/employer/${facilitySel}/availabilities`}
                      logo={<EventAvailableTwoTone tw="mr-2" />}
                      title="Verfügbarkeiten"
                      isEmployer
                    />
                    <NavLink
                      onClick={e => handleClick(e)}
                      to={`/employer/${facilitySel}/create-job`}
                      logo={<AddBoxTwoTone tw="mr-2" />}
                      title="Job erstellen"
                      isEmployer
                    />
                    <NavLink
                      onClick={e => handleClick(e)}
                      to={`/employer/${facilitySel}/jobs-current`}
                      logo={<NextWeekTwoTone tw="mr-2" />}
                      title="Anstehende Einsätze"
                      isEmployer
                    />
                    <NavLink
                      onClick={e => handleClick(e)}
                      to={`/employer/${facilitySel}/jobs-past`}
                      logo={
                        <NextWeekTwoTone
                          tw="mr-2"
                          sx={{ transform: "scaleX(-1)" }}
                        />
                      }
                      title="Vergangene Einsätze"
                      isEmployer
                    />
                  </TransitionWrapper>
                )}
              </Transition>
            </TransitionGroup>
          </div>
        </>
      )}
      {roles.includes("WORKER") && (
        <div tw="flex-grow overflow-y-auto">
          <NavLink
            onClick={handleClick}
            to="/jobs/find"
            logo={<SearchTwoTone tw="mr-2" />}
            title="Jobs finden"
          />
          <NavLink
            onClick={handleClick}
            to="/jobs/current"
            logo={<NextWeekTwoTone tw="mr-2" />}
            title="Anstehende Einsätze"
          />
          <NavLink
            onClick={handleClick}
            to="/jobs/past"
            logo={
              <NextWeekTwoTone tw="mr-2" sx={{ transform: "scaleX(-1)" }} />
            }
            title="Vergangene Einsätze"
          />
          {/* <NavLink onClick={handleClick} to="/personal-data/" logo={<AccountCircleTwoTone tw="mr-2" />} title="Persönliche Daten" /> */}
          <NavLink
            onClick={handleClick}
            to="/avails-user"
            logo={<EventAvailableTwoTone tw="mr-2" />}
            title="Deine Verfügbarkeiten"
          />
        </div>
      )}
      {roles.includes("ADMIN") && (
        <>
          <AdminNavLink
            onClick={handleClick}
            to="/admin-manage-users"
            title="Nutzer verwalten"
          />
          <AdminNavLink
            onClick={handleClick}
            to="/admin-manage-pools"
            title="Pools verwalten"
          />
          <AdminNavLink
            onClick={handleClick}
            to="/admin-create-job"
            title="Jobs erstellen"
          />
          <AdminNavLink
            onClick={handleClick}
            to="/admin-scripts"
            title="Scripts"
          />
        </>
      )}
      <div tw="flex flex-wrap pt-6 pb-2 text-sm sm:text-xs">
        <FooterLink href="mailto:kontakt@morrowmed.de">Kontakt</FooterLink>
        {/* <FooterLink href="tel:0176 55501817">
          <HelpTwoTone tw="mr-2 text-xl" />
          Support kontaktieren
        </FooterLink> */}
        <div
          tw="flex items-center px-4 py-1 cursor-pointer opacity-90 hover:underline"
          onClick={() => setShowSupportDialog(true)}
        >
          Support
        </div>
        <FooterLinkToMM to="/impressum" title="Impressum" />
        <FooterLinkToMM to="/user-agreements" title="Nutzungsbedingungen" />
        <FooterLinkToMM
          to="https://morrowmed.notion.site/morrowmed/Willkommen-beim-JiM-Support-b2370c9c8648415a8781154454431ac3"
          title="HowTo"
        />
        <FooterLinkToMM to="/privacy" title="Datenschutz" />
        <FooterLinkToMM to="/" title="Über JiM" />
      </div>
      <JimDialog
        open={showSupportDialog}
        handleClose={() => setShowSupportDialog(false)}
      >
        <TextButton>
          <a tw="flex items-center" href="mailto:support@morrowmed.de">
            <EmailRounded tw="text-xl mr-2" />
            support@morrowmed.de
          </a>
        </TextButton>
      </JimDialog>
    </>
  );
};
NavDrawer.propTypes = {
  setMobileOpen: func.isRequired,
};

export default NavDrawer;
